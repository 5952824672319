import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProfilePage from "./profile/ProfilePage";
import CompanyPage from "./company/CompanyPage";
import AclPage from "./acl/AclPage";
import FilialPage from "./filiais/FilialPage";
import ProductPage from "./product/ProductPage";
import UsersPage from "./users/UsersPage";
import OrderPage from "./order/OrderPage";
import SellerPage from "./sellers/SellerPage";
import ScriptsPage from "./scripts/ScriptsPage";
import PaymentLinkPage from "./payment_link/PaymentLinkPage";
import CatalogXmlPage from "./catalog_xml/CatalogXmlPage";
import Dashboard from "./Dashboard";
import { LayoutSplashScreen } from "../../../_metronic";

export default function MainPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/company" component={CompanyPage} />
        <Route path="/acl" component={AclPage} />
        <Route path="/sellers" component={SellerPage} />
        <Route path="/filiais" component={FilialPage} />
        <Route path="/products" component={ProductPage} />
        <Route path="/users" component={UsersPage}/>
        <Route path="/orders" component={OrderPage}/>
        <Route path="/payment_links" component={PaymentLinkPage} />
        <Route path="/catalog_xml" component={CatalogXmlPage} />
        <Route path="/scripts" component={ScriptsPage} />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}

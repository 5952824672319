import React from "react";
import { Route, Switch } from "react-router-dom";
import FilialIndex from "./FilialIndex";
import FilialAdd from "./FilialAdd";
import FilialEdit from "./FilialEdit";

export default function CompanyPage() {
  return (
    <Switch>
      <Route exact path="/filiais" component={FilialIndex} />
      <Route exact path="/filiais/add" component={FilialAdd} />
      <Route exact path="/filiais/edit/:id" component={FilialEdit} />
    </Switch>
  );
}

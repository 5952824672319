import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { CameraAlt, Panorama, Add } from "@material-ui/icons";

import { Modal } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  normalizeCnpj,
  toCurrency,
  normalizeCurrency,
} from "../utils/normalize";

import { toast } from "react-toastify";

function ProductAdd({ match }) {
  const { id } = match.params;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);
  const [openMedia, setOpenMedia] = useState(false);
  const [activeMedia, setActiveMedia] = useState(null);
  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("/me");
      setUser(data);
    }
    loadUser();
  }, []);

  const [values, setValues] = useState({
    name: "",
    description: "",
    value: "",
    e_rede_credencial_id: "",
    active: 1,
    image: "",
    medias: [],
  });
  const [mediaAdd, setMediaAdd] = useState({
    type: "",
    source: "",
    content: "",
    image: null,
  });

  const previewMedia = useMemo(() => {
    return mediaAdd.image ? URL.createObjectURL(mediaAdd.image) : null;
  }, [mediaAdd.image])

  const [removeMediaConfirm, setRemoveMediaConfirm] = useState(false);
  const [credencialsOptions, setCredencialOptions] = useState([]);

  async function loadData() {
    const { data } = await axios.get(`/products/${id}`);
    setValues({
      ...data.product,
      value: normalizeCurrency(data.product.value),
    });
  }

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]); // eslint-disable-line

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get("/filials");
      setCredencialOptions(data);
    }
    loadData();
  }, []);

  useEffect(() => {
    setRemoveMediaConfirm(false)
  }, [activeMedia])

  async function handleAddMedia() {
    setLoading(true)
    try {
      const obj = new FormData()
      obj.append('image', mediaAdd.image)
      obj.append('type', mediaAdd.type)
      obj.append('source', mediaAdd.source)
      obj.append('content', mediaAdd.content)
      obj.append('product_id', id)

      await axios.post("/product-media", obj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      setMediaAdd({
        type: "",
        source: "",
        content: "",
        image: null,
      })
      toast.info('Mídia adicionada')
      loadData()
    }catch(err) {

    }
    setLoading(false)
  }

  async function handleSaveMedia() {
    setLoading(true)
    try {
      const obj = new FormData()
      obj.append('content', values.medias[activeMedia].content)

      await axios.put(`/product-media/${values.medias[activeMedia].id}`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      toast.info('Mídia atualizada')

      loadData()
    }catch(err) {

    }
    setLoading(false)
  }

  async function handleRemoveMedia() {
    setLoading(true)
    try {
      const obj = new FormData()
      obj.append('content', values.medias[activeMedia].content)

      await axios.delete(`/product-media/${values.medias[activeMedia].id}`)
      toast.info('Mídia Deletada')

      setActiveMedia(null)

      loadData()
    }catch(err) {

    }
    setLoading(false)
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const obj = new FormData();
      if (image) {
        obj.append("image", image);
      }
      obj.append("name", values.name);
      obj.append("description", values.description);
      obj.append(
        "value",
        Number(values.value.replace(/[^\d]+/g, "").replace(" ", ""))
      );
      obj.append("e_rede_credencial_id", values.e_rede_credencial_id);
      obj.append("active", values.active);
      await axios.put(`/products/${id}`, obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setValues({
        name: "",
        description: "",
        value: "",
        e_rede_credencial_id: "",
        medias: []
      });
      toast.info("Produto atualizado");
      history.push("/products");
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Editar Produto</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/products`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/products/edit/${id}`}
              >
                Editar Produto
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={openMedia}
        onHide={() => setOpenMedia(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="row">
            <div className="col-md-10" style={{ border: "1px solid #eee" }}>
              {activeMedia === "add" ? (
                <div className="p-4 d-flex flex-column">
                  <TextField
                    label="Tipo"
                    value={mediaAdd.type}
                    onChange={(e) =>
                      setMediaAdd({
                        ...mediaAdd,
                        type: e.target.value,
                      })
                    }
                    disabled={loading}
                    select
                  >
                    <option value="image">Imagem</option>
                    <option value="embed">Link de Incorporação</option>
                  </TextField>
                  {mediaAdd.type === "image" && (
                    <>
                      <div
                        className="mt-3"
                        style={{
                          width: "100%",
                          minHeight: "334px",
                          backgroundImage: `url(${
                            previewMedia
                          })`,
                          backgroundSize: "contain",
                          backgroundPosition: "50% 50%",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "#eee",
                        }}
                      ></div>
                      <label
                        htmlFor="file_image"
                        className="btn btn-upper btn-primary"
                      >
                        <h5 className="mb-0">Escolher Imagem</h5>
                        <input
                          type="file"
                          id="file_image"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setMediaAdd({
                              ...mediaAdd,
                              image: e.target.files[0],
                            })
                          }
                        />
                      </label>
                    </>
                  )}
                  {mediaAdd.type === '' && (
                    <div style={{ minHeight: '334px', width: '100%' }}></div>
                  )}
                  {mediaAdd.type === "embed" && (
                    <>
                      <div className="embed-responsive embed-responsive-16by9 desc_media mt-3" style={{ minHeight: '334px' }}>
                        <iframe className="embed-responsive-item" src={mediaAdd.source} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                      <TextField
                        label="Link de Incorporação"
                        value={mediaAdd.source}
                        onChange={(e) =>
                          setMediaAdd({ ...mediaAdd, source: e.target.value })
                        }
                        helperText="Apenas a informação de 'src' deve ser inserida"
                        disabled={loading}
                      />
                    </>
                  )}
                  <TextField
                    label="Texto"
                    value={mediaAdd.content}
                    onChange={(e) =>
                      setMediaAdd({ ...mediaAdd, content: e.target.value })
                    }
                    rows={4}
                    disabled={loading}
                    multiline
                  />
                  <hr />
                  <div className="d-flex">
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      disabled={mediaAdd.type === "" || loading}
                      onClick={handleAddMedia}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                {activeMedia !== null && (
                  <div className="p-4 d-flex flex-column">
                    <TextField
                    label="Tipo"
                    value={values.medias[activeMedia].type}
                    disabled
                    select
                  >
                    <option value="image">Imagem</option>
                    <option value="embed">Link de Incorporação</option>
                  </TextField>
                    {values.medias[activeMedia].type === 'image' && (
                      <div
                        className="mt-3"
                        style={{
                          width: "100%",
                          minHeight: "334px",
                          backgroundImage: `url(${
                            values.medias[activeMedia].source
                          })`,
                          backgroundSize: "contain",
                          backgroundPosition: "50% 50%",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "#eee",
                        }}
                      ></div>
                    )}
                    {values.medias[activeMedia].type === 'embed' && (
                      <div className="embed-responsive embed-responsive-16by9 desc_media mt-3">
                        <iframe className="embed-responsive-item" src={values.medias[activeMedia].source} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    )}
                    <TextField
                      label="Texto"
                      value={values.medias[activeMedia].content}
                      onChange={(e) => setValues({
                        ...values,
                        medias: values.medias.map((m, i) => {
                          if(i === activeMedia) {
                            return {
                              ...m,
                              content: e.target.value
                            }
                          }
                          return m
                        })
                      })}
                      rows={4}
                      disabled={loading}
                      multiline
                      className="mb-3"
                    />
                    {!removeMediaConfirm && (
                      <div className="d-flex">
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          disabled={loading}
                          onClick={handleSaveMedia}
                          >
                            Salvar
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          className="ml-2"
                          variant="outlined"
                          disabled={loading}
                          onClick={() => setRemoveMediaConfirm(true)}
                          >
                            Deletar
                        </Button>
                      </div>
                    )}
                    {removeMediaConfirm && (
                      <>
                      <h5>Tem certeza que deseja remover esta mídia?</h5>
                      <div className="d-flex">
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          disabled={loading}
                          onClick={handleRemoveMedia}
                          >
                            Sim
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          variant="outlined"
                          className="ml-2"
                          disabled={loading}
                          onClick={() => setRemoveMediaConfirm(false)}
                          >
                            Não
                        </Button>
                      </div>
                      </>
                    )}
                  </div>
                )}
                {activeMedia === null && (
                  <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '334px' }}>
                    <h4>Nenhuma mídia selecionada</h4>
                  </div>
                )}
                </>
              )}
            </div>
            <div
              className="col-md-2"
              style={{
                borderLeft: "2px solid #eee",
                borderRight: "2px solid #eee",
              }}
            >
              {values.medias.map((media, index) => (
                <button
                  type="button"
                  className={`btn btn-block btn-sm text-left ${activeMedia === index ? 'btn-primary' : 'btn-dark'}`}
                  onClick={() => setActiveMedia(index)}
                  key={media.id}
                >
                  {`#${index + 1}`}
                </button>
              ))}
              <button
                type="button"
                className={`btn btn-block btn-sm text-left ${activeMedia === 'add' ? 'btn-primary' : 'btn-dark'}`}
                onClick={() => setActiveMedia("add")}
              >
                <Add />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Editar Produto</legend>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <label
                htmlFor="image_input"
                className="mr-3"
                style={{
                  width: "120px",
                  height: "120px",
                  border: "1px solid #c9c9c9",
                  borderRadius: "4px",
                  backgroundImage: preview
                    ? `url(${preview})`
                    : `url(${process.env.REACT_APP_API_BASE_URL}/files/${values.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CameraAlt />
                <input
                  type="file"
                  id="image_input"
                  style={{ display: "none" }}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </label>
              <small>Máximo de 2MB</small>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "5px",
              }}
            >
              <TextField
                size="small"
                label="Nome do Produto"
                variant="outlined"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
              <TextField
                size="small"
                label="Descrição"
                variant="outlined"
                value={values.description}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
              />
              <TextField
                size="small"
                label="Valor"
                multiline
                variant="outlined"
                value={values.value}
                onChange={(e) =>
                  setValues({ ...values, value: toCurrency(e.target.value) })
                }
              />
              <FormControl size="small" variant="outlined">
                <InputLabel id="filial-label">Filial</InputLabel>
                <Select
                  size="small"
                  labelId="filial-label"
                  value={values.e_rede_credencial_id}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      e_rede_credencial_id: e.target.value,
                    })
                  }
                  disabled={user.e_rede_credencial_id !== null}
                >
                  {credencialsOptions.length > 0 &&
                    credencialsOptions.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.filial} | {normalizeCnpj(i.cnpj)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="medium"
                    checked={values.active === 1}
                    onChange={() =>
                      setValues({
                        ...values,
                        active: values.active === 1 ? 0 : 1,
                      })
                    }
                  />
                }
                label="Ativo"
              />
            </div>
          </div>
        </fieldset>
        <Button
          color="secondary"
          variant="contained"
          className="mt-4 mr-2"
          disabled={
            loading ||
            values.name === "" ||
            values.description === "" ||
            values.value === "" ||
            values.e_rede_credencial_id === "" ||
            values.value === "R$ 0,00"
          }
          onClick={() => setOpenMedia(true)}
          startIcon={<Panorama />}
        >
          Gerenciar Mídia
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={
            loading ||
            values.name === "" ||
            values.description === "" ||
            values.value === "" ||
            values.e_rede_credencial_id === "" ||
            values.value === "R$ 0,00"
          }
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </form>
    </>
  );
}

export default ProductAdd;

import React, { useState } from "react";

import axios from 'axios';

import { Link } from 'react-router-dom';

import { TextField, Button } from "@material-ui/core";

import { toast } from 'react-toastify';

function PermissionAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    slug: "",
    name: "",
    description: "",
  });

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post('/permissions', values);
      setValues({
        slug: "",
        name: "",
        description: "",
      });
      toast.info('Permissão cadastrada');
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Criar Permissão</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/acl/permissions`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/acl/permissions/add`}>
                Criar Permissão
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Nova Permissão</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Slug"
              variant="outlined"
              value={values.slug}
              onChange={(e) =>
                setValues({ ...values, slug: e.target.value })
              }
            />
            <TextField
              label="Nome"
              variant="outlined"
              value={values.name}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value })
              }
            />
            <TextField
              label="Descrição"
              multiline
              variant="outlined"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </div>
        </fieldset>
        <Button color="primary" variant="contained" className="mt-4" disabled={loading || values.slug === "" || values.name === "" || values.description === ""} onClick={handleSubmit}>
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default PermissionAdd;

import React from "react";
import { Route, Switch } from "react-router-dom";
import UsersIndex from "./UsersIndex";
import UsersAdd from "./UsersAdd";
import UsersEdit from "./UsersEdit";

export default function ProductPage() {
  return (
    <Switch>
      <Route exact path="/users" component={UsersIndex} />
      <Route exact path="/users/add" component={UsersAdd} />
      <Route exact path="/users/edit/:id" component={UsersEdit} />
    </Switch>
  );
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import OrderIndex from "./OrderIndex";

export default function OrderPage() {
  return (
    <Switch>
      <Route exact path="/orders" component={OrderIndex} />
    </Switch>
  );
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import SellerIndex from "./SellerIndex";
import SellerAdd from "./SellerAdd";
import SellerEdit from "./SellerEdit";

export default function ProductPage() {
  return (
    <Switch>
      <Route exact path="/sellers" component={SellerIndex} />
      <Route exact path="/sellers/add" component={SellerAdd} />
      <Route exact path="/sellers/edit/:id" component={SellerEdit} />
    </Switch>
  );
}

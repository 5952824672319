import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import axios from "axios";

function ForgotPassword(){
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ type: null, msg: '' })

  async function handleSubmit() {
    setStatus({ type: 'null', msg: '' });
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return setStatus({ type: 'danger', msg: 'Email não é válido.' });
    }
    setLoading(true);
    try {
      await axios.post('request-password', { email });
      setStatus({ type: 'success', msg: 'Foi enviado um email com um link de recuperação.' })
    }catch(err) {
      if(err.response && err.response.status === 404) {
        setStatus({ type: 'danger', msg: 'Email não encontrado.' });
      }else{
        setStatus({ type: 'danger', msg: 'Ocorreu um erro, tente mais tarde.' })
      }
    }
    setLoading(false);
  }

    return (
        <div className="kt-login__body login_wrap_logo" style={{ height: '100%' }}>
          <h3 style={{ width: '100%', textAlign: 'center' }}>
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
          <div className="kt-login__body" style={{ width: '100%', maxWidth: '400px', height: 'auto' }}>
            <div className="kt-login__form">
            {status.type !== null && status.msg !== '' && (
                <div role="alert" className={`alert alert-${status.type}`}>
                  <div className="alert-text">{status.msg}</div>
                </div>
              )}
                      <div className="form-group">
                        <TextField
                            type="email"
                            label="Email"
                            margin="normal"
                            fullWidth={true}
                            name="email"
                            value={email}
                            disabled={loading}
                            onChange={e => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="kt-login__actions">
                        <Link to="/auth">
                          <button
                              type="button"
                              className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                          >
                            Voltar
                          </button>
                        </Link>

                        <button
                            type="submit"
                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                            disabled={loading}
                            onClick={handleSubmit}
                        >
                          Recuperar
                        </button>
                      </div>
            </div>
          </div>
        </div>
    );
  }

export default ForgotPassword;

import React from "react";
import { Route, Switch } from "react-router-dom";
import CatalogXmlIndex from "./CatalogXmlIndex";

export default function PaymentLinkPage() {
  return (
    <Switch>
      <Route exact path="/catalog_xml" component={CatalogXmlIndex} />
    </Switch>
  );
}

export const normalizeCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}.${onlyNums.slice(5, 8)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function emailIsValid(email) {
  if(email.length < 3) {
    return false;
  }
  if(email.indexOf('@') === -1) {
    return false;
  }
  const splited = email.split('@');

  if(splited[1].indexOf('.') === -1) {
    return false;
  }

  return true;
}

export const normalizeCurrency = (value) => {
  const stringOfNumber = String(value);

  if(stringOfNumber.length === 1) {
    return '0,0'+stringOfNumber;
  }else if(stringOfNumber.length === 2) {
    return '0,'+stringOfNumber;
  }

  let cents = stringOfNumber[stringOfNumber.length-2] + stringOfNumber[stringOfNumber.length-1];
  let reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  let arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((value, index) => {
    if((index + 1)%3 === 0) {
      if(index === arrayNumber.length - 1) {
        pointValue = value + pointValue;
      }else{
        pointValue = "." + value + pointValue;
      }
    }else{
      pointValue = value + pointValue;
    }
  });
  return pointValue + "," + cents;
}

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = digits => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = number => {
  const centsStartingPosition = number.length - 2

  const cents = number.substring(centsStartingPosition)
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))
  
  return `${dollars},${cents}`
}

const handleThousands = number => {
  const dollarsStartingPosition = number.length - 6

  const dollars = number.substring(dollarsStartingPosition)
  const thousands = removeLeadingZeros(number.substring(0, dollarsStartingPosition))

  return `${thousands}.${dollars}`
}

const handleMillions = number => {
  const thousandsStartingPosition = number.length - 10

  const thousands = number.substring(thousandsStartingPosition)
  const millions = removeLeadingZeros(number.substring(0, thousandsStartingPosition))

  return `${millions}.${thousands}`
}

export const toCurrency = value => {
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  const realNumber = removeLeadingZeros(digitsWithPadding);

  if (realNumber.length <= 5) {
    return `R$ ${addDecimalToNumber(digitsWithPadding)}`
  } else {
    if (realNumber.length > 5 && realNumber.length <=8) {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      return `R$ ${handleThousands(numberWithDecimals)}`
    } else {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      const thousandsWithDecimals = handleThousands(numberWithDecimals)
      return `R$ ${handleMillions(thousandsWithDecimals)}`;
    }
  }
}

import React, { useState, useMemo, useEffect } from "react";

import axios from 'axios';

import { TextField, Button } from "@material-ui/core";
import { normalizeCnpj, validarCNPJ, emailIsValid } from "../utils/normalize";

import { CameraAlt } from "@material-ui/icons";

import { toast } from 'react-toastify';

function CompanyEdit() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    social_reason: "",
    cnpj: "",
    email: "",
    user_name: "",
    user_email: "",
    color_primary: "#000",
    color_secondary: "#000",
    logo_image: "",
  });
  const [image, setImage] = useState(null);
  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);
  const [errors, setErrors] = useState({
    social_reason: "",
    cnpj: "",
    email: "",
    color_primary: "",
    color_secondary: "",
  });

  async function handleSubmit() {
    setErrors({
      social_reason: "",
      cnpj: "",
      email: "",
      color_primary: "",
      color_secondary: "",
    });
    let lock_error = {
      social_reason: "",
      cnpj: "",
      email: "",
      color_primary: "",
      color_secondary: "",
    }
    let count = 0;
    if(values.social_reason === '') {
      lock_error.social_reason = 'PREENCHA ESSE CAMPO';
      count += 1;
    }
    if(values.cnpj.length < 18) {
      lock_error.cnpj = 'PREENCHA ESSE CAMPO';
      count += 1;
    }else if(!validarCNPJ(values.cnpj)) {
      lock_error.cnpj = 'CNPJ NÃO É VÁLIDO';
      count += 1;
    }

    if(!emailIsValid(values.email)) {
      lock_error.email = 'E-MAIL NÃO É VÁLIDO';
      count += 1;
    }
    if(count > 0) {
      return setErrors(lock_error);
    }
    setLoading(true);
    try {
      const obj = new FormData();
      if(image) {
        obj.append("image", image);
      }
      obj.append('social_reason', values.social_reason);
      obj.append('email', values.email);
      obj.append('color_primary', values.color_primary);
      obj.append('color_secondary', values.color_secondary);
      await axios.put('/company', obj);
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get('/me');
        console.log(data.company)
        setValues({ ...data.company, cnpj: normalizeCnpj(data.company.cnpj) });
      }catch(err) {

      }
      setLoading(false);
    }
    loadData();
  }, []);

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Editar Empresa</h3>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <div className="d-flex">
          <div className="d-flex flex-column">
              <label
                htmlFor="image_input"
                className="mr-3"
                style={{
                  width: "120px",
                  height: "120px",
                  border: "1px solid #c9c9c9",
                  borderRadius: "4px",
                  backgroundImage: preview ? `url(${preview})` : `url(${process.env.REACT_APP_API_BASE_URL}/files/${values.logo_image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CameraAlt />
                <input
                  type="file"
                  id="image_input"
                  style={{ display: "none" }}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </label>
              <small>
                Máximo de 2MB
              </small>
            </div>
            <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Razão Social"
              error={errors.social_reason}
              variant="outlined"
              value={values.social_reason}
              onChange={(e) =>
                setValues({ ...values, social_reason: e.target.value })
              }
            />
            <TextField
              label="CNPJ"
              error={errors.cnpj}
              variant="outlined"
              value={values.cnpj}
              onChange={(e) =>
                setValues({ ...values, cnpj: normalizeCnpj(e.target.value) })
              }
              disabled
            />
            <TextField
              label="E-mail"
              error={errors.email}
              variant="outlined"
              value={values.email}
              onChange={(e) =>
                setValues({ ...values, email: e.target.value })
              }
            />
            <div className="d-flex">
            <TextField
              style={{ minWidth: '120px' }}
              type="color"
              label="Cor Primária"
              error={errors.color_primary}
              variant="outlined"
              value={values.color_primary}
              onChange={(e) =>
                setValues({ ...values, color_primary: e.target.value })
              }
            />
            <TextField
              style={{ minWidth: '120px' }}
              className="ml-4"
              type="color"
              label="Cor Secundária"
              error={errors.color_secondary}
              variant="outlined"
              value={values.color_secondary}
              onChange={(e) =>
                setValues({ ...values, color_secondary: e.target.value })
              }
            />
            </div>
          </div>
          </div>
        </fieldset>
        <Button color="primary" variant="contained" className="mt-4" disabled={loading} onClick={handleSubmit}>
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default CompanyEdit;
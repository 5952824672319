import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { TextField, Button } from "@material-ui/core";

import { toast } from "react-toastify";

function RoleAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    slug: "",
    name: "",
    description: "",
  });
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    async function loadPermissions() {
      try {
        const { data } = await axios.get("/permissions");
        setPermissionsOptions(data);
      } catch (err) {
        if (err.response && err.response.status === 403) {
          toast.error("Você não tem permissão para este recurso");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.msg);
        } else if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores, contate a equipe técnica"
          );
        } else {
          toast.error("Ocorreu um erro, tente novamente");
        }
      }
    }
    loadPermissions();
  }, []);

  function handleSelect(item) {
    if (permissions.find((i) => i === item.id)) {
      setPermissions(permissions.filter((i) => i !== item.id));
    } else {
      setPermissions([...permissions, item.id]);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post("/roles", {...values, permissions });
      setValues({
        slug: "",
        name: "",
        description: "",
      });
      setPermissions([]);
      toast.info("Perfil cadastrado");
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Criar Perfil</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/acl/roles`}
              >
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/acl/roles/add`}
              >
                Criar Perfil
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Novo Perfil</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Slug"
              variant="outlined"
              value={values.slug}
              onChange={(e) => setValues({ ...values, slug: e.target.value })}
            />
            <TextField
              label="Nome"
              variant="outlined"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
            <TextField
              label="Descrição"
              multiline
              variant="outlined"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </div>
        </fieldset>
        <fieldset className="mt-4 mb-4">
          <legend>Permissões</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            {permissionsOptions.length > 0 &&
              permissionsOptions.map((p) => {
                const selected = permissions.find((i) => i === p.id);
                return (
                  <button
                    type="button"
                    style={{ background: '#f9f9f9', borderRadius: '4px', border: '1px solid #eee', padding: '10px' }}
                    key={p.id}
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => handleSelect(p)}
                  >
                    <span className="mr-4 d-flex justify-content-center align-items-center" style={{ background: '#eee', padding: '5px', width: '20px', height: '20px' }}>{selected && <i className="fas fa-check"></i>}</span>
                    {p.name}
                  </button>
                );
              })}
          </div>
        </fieldset>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={
            loading ||
            values.slug === "" ||
            values.name === "" ||
            values.description === "" || permissions.length === 0
          }
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default RoleAdd;

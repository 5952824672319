import React from "react";
import { Route, Switch } from "react-router-dom";
import PaymentLinkIndex from "./PaymentLinkIndex";
import PaymentLinkAdd from "./PaymentLinkAdd";

export default function PaymentLinkPage() {
  return (
    <Switch>
      <Route exact path="/payment_links" component={PaymentLinkIndex} />
      <Route exact path="/payment_links/add" component={PaymentLinkAdd} />
    </Switch>
  );
}

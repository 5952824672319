import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link, useHistory } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import { normalizeCnpj } from "../utils/normalize";

import { toast } from "react-toastify";

function SellerEdit({ match }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    code: "",
    e_rede_credencial_id: "",
    name: "",
    active: 1,
  });

  const { id } = match.params;

  const [filials, setFilials] = useState([]);

  useEffect(() => {
    async function loadFilials() {
      const { data } = await axios.get(
        "/e-rede-credencials?page=1&perPage=100000"
      );
      setFilials(data.data);
    }
    loadFilials();
  }, []);

  useEffect(() => {
    async function loadDetail() {
      setLoading(true)
      try {
        const { data } = await axios.get(`/sellers/${id}`)
        setValues(data);
      }catch(err) {
        if (err.response && err.response.status === 403) {
          toast.error("Você não tem permissão para este recurso");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.message);
        } else if (err.response && err.response.status === 404) {
          toast.error("Vendedor não encontrado");
        } else if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores, contate a equipe técnica"
          );
        } else {
          toast.error("Ocorreu um erro, tente novamente");
        }
      }
      setLoading(false)
    }
    loadDetail()
  }, [id])

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.put(`/sellers/${id}`, values);
      toast.info("Vendedor atualizado");
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Editar Vendedor</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/sellers`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/sellers/edit/${id}`}
              >
                Editar Vendedor
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Editar Vendedor</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
              marginBottom: "15px",
            }}
          >
            <TextField
              label="Nome"
              variant="outlined"
              value={values.name}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value })
              }
            />
            <TextField
              label="Código"
              variant="outlined"
              value={values.code}
              onChange={(e) =>
                setValues({ ...values, code: e.target.value.toUpperCase() })
              }
            />
            <FormControl variant="outlined">
              <InputLabel id="discount_type-label">Filial</InputLabel>
              <Select
                labelId="discount_type-label"
                value={values.e_rede_credencial_id}
                onChange={(e) =>
                  setValues({ ...values, e_rede_credencial_id: e.target.value })
                }
              >
                {filials.map(f => (
                  <MenuItem value={f.id} key={f.id}>{f.filial} - {normalizeCnpj(f.cnpj)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="active-label">Ativo</InputLabel>
              <Select
                labelId="active-label"
                value={values.active}
                onChange={(e) =>
                  setValues({ ...values, active: e.target.value })
                }
              >
                <MenuItem value={1} key={1}>Sim</MenuItem>
                <MenuItem value={0} key={0}>Não</MenuItem>
              </Select>
            </FormControl>
          </div>
        </fieldset>
        <div className="d-flex mt-4">
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className="ml-2"
            disabled={loading}
            onClick={() => history.push("/sellers")}
          >
            Voltar
          </Button>
        </div>
      </form>
    </>
  );
}

export default SellerEdit;

import React from "react";
import { Route, Switch } from "react-router-dom";
import PermissionIndex from "./PermissionIndex";
import PermissionAdd from "./PermissionAdd";
import PermissionEdit from "./PermissionEdit";
import RoleIndex from "./RoleIndex";
import RoleAdd from "./RoleAdd";
import RoleEdit from "./RoleEdit";


export default function AclPage() {
  return (
    <Switch>
      <Route exact path="/acl/permissions" component={PermissionIndex} />
      <Route exact path="/acl/permissions/add" component={PermissionAdd} />
      <Route exact path="/acl/permissions/edit/:id" component={PermissionEdit} />
      <Route exact path="/acl/roles" component={RoleIndex} />
      <Route exact path="/acl/roles/add" component={RoleAdd} />
      <Route exact path="/acl/roles/edit/:id" component={RoleEdit} />
    </Switch>
  );
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import ProductIndex from "./ProductIndex";
import ProductAdd from "./ProductAdd";
import ProductEdit from "./ProductEdit";

export default function ProductPage() {
  return (
    <Switch>
      <Route exact path="/products" component={ProductIndex} />
      <Route exact path="/products/add" component={ProductAdd} />
      <Route exact path="/products/edit/:id" component={ProductEdit} />
    </Switch>
  );
}

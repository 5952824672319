import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextField, CircularProgress } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

import axios from "axios";

function Registration({ match }) {
  const { token } = match.params; 
  const history = useHistory();

  useEffect(() => {
    if(!token) {
      toast.error("Link não é válido");
      history.push('/auth');
    }
  }, [token]); // eslint-disable-line

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });
  const [status, setStatus] = useState({ type: null, msg: '' });
  const [loading, setLoading] = useState(false);

  async function SubmitRegister() {
    setLoading(true);
    setStatus({ type: null, msg: '' });
    setError({
      password: false,
      confirmPassword: false,
    });

    let lockError = {
      password: false,
      confirmPassword: false,
    }
    if(values.password === "" || values.password.length < 6) {
      lockError.password = true;
    }
    if(values.confirmPassword === "") {
      lockError.confirmPassword = true;
    }
    if(values.confirmPassword !== values.password) {
      setStatus({ type: 'danger', msg: 'Senhas não coincidem' });
      lockError.password = true;
      lockError.confirmPassword = true;
    }
    if(lockError.password || lockError.confirmPassword) {
      setLoading(false);
      return setError(lockError);
    }
    try {
      await axios.put(`/recovery-password/${token}`, { password: values.password });
      setStatus({ type: 'success', msg: (
        <>
          Conta recuperada com sucesso! 
          <Link to="/auth">
          Clique aqui para fazer Login.
          </Link>
        </>
      ) });
      setValues({
        password: "",
        confirmPassword: "",
      });
    }catch(err) {
      if(err.response.status === 401) {
        setStatus({ type: 'danger', msg: (<>Token de autenticação expirado. 
        <Link to="/auth/forgot-password">
          Clique aqui para fazer uma nova requisição.
        </Link></>) });
      }else{
        setStatus({ type: 'danger', msg: 'Ocorreu um erro, tente novamente.' });
      }
    }
    setLoading(false);
  }

  return (
    <div className="kt-login__body login_wrap_logo">
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={toAbsoluteUrl("/media/logos/logo-4-blue.png")} className="mr-4" alt="Logo" style={{ width: '100%', maxWidth: '150px' }}/>
          <h3>
            Recuperar Senha
          </h3>
        </div>
              {status.type !== null && status.msg !== '' && (
                <div role="alert" className={`alert alert-${status.type}`}>
                  <div className="alert-text">{status.msg}</div>
                </div>
              )}
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ marginRight: '5px' }}>
                <TextField
                  type="password"
                  margin="normal"
                  label="Senha"
                  className="kt-width-full"
                  name="password"
                  value={values.password || ""}
                  onChange={e => setValues({ ...values, password: e.target.value })}
                  error={error.password}
                  helperText="Mínimo de 06 caracteres"
                />
              </div>

              <div style={{ marginLeft: '5px' }}>
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirmar Senha"
                  className="kt-width-full"
                  name="confirmPassword"
                  value={values.confirmPassword || ""}
                  onChange={e => setValues({ ...values, confirmPassword: e.target.value })}
                  error={error.confirmPassword}
                />
              </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-elevate kt-login__btn-primary btn-block mb-4"
                  onClick={SubmitRegister}
                  disabled={loading}
                  style={{ marginTop: '16px' }}
                >
                  {loading ? <CircularProgress style={{ color: '#fff' }} size={12} /> : 'Recuperar'}
                </button>
              </div>
    </div>
    </div>
  );
}

export default Registration;

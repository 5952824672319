import React, { useState } from "react";

import axios from 'axios';

import { TextField, Button } from "@material-ui/core";
import { normalizeCnpj, validarCNPJ, emailIsValid } from "../utils/normalize";

import { toast } from 'react-toastify';

function CompanyAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    social_reason: "",
    cnpj: "",
    company_email: "",
    user_name: "",
    user_email: "",
    color_primary: "#000",
    color_secondary: "#000",
  });
  const [errors, setErrors] = useState({
    social_reason: "",
    cnpj: "",
    company_email: "",
    user_name: "",
    user_email: "",
    color_primary: "",
    color_secondary: "",
  });

  async function handleSubmit() {
    setErrors({
      social_reason: "",
      cnpj: "",
      company_email: "",
      user_name: "",
      user_email: "",
      color_primary: "",
      color_secondary: "",
    });
    let lock_error = {
      social_reason: "",
      cnpj: "",
      company_email: "",
      user_name: "",
      user_email: "",
      color_primary: "",
      color_secondary: "",
    }
    let count = 0;
    if(values.social_reason === '') {
      lock_error.social_reason = 'PREENCHA ESSE CAMPO';
      count += 1;
    }
    if(values.cnpj.length < 18) {
      lock_error.cnpj = 'PREENCHA ESSE CAMPO';
      count += 1;
    }else if(!validarCNPJ(values.cnpj)) {
      lock_error.cnpj = 'CNPJ NÃO É VÁLIDO';
      count += 1;
    }

    if(!emailIsValid(values.company_email)) {
      lock_error.company_email = 'E-MAIL NÃO É VÁLIDO';
      count += 1;
    }
    if(values.user_name.length < 3) {
      lock_error.user_name = 'PREENCHA ESSE CAMPO';
      count += 1;
    }
    if(!emailIsValid(values.user_email)) {
      lock_error.user_email = 'E-MAIL NÃO É VÁLIDO';
      count += 1;
    }
    if(count > 0) {
      return setErrors(lock_error);
    }
    setLoading(true);
    try {
      await axios.post('/company', {
        social_reason: values.social_reason,
        cnpj: values.cnpj.replace(/[^\d]+/g, ""),
        email: values.company_email,
        user: {
          name: values.user_name,
          email: values.user_email,
        },
        color_primary: values.color_primary,
        color_secondary: values.color_secondary,
      });
      setValues({
        social_reason: "",
        cnpj: "",
        company_email: "",
        user_name: "",
        user_email: "",
        color_primary: "#000",
        color_secondary: "#000",
      });
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Criar Empresa</h3>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Empresa</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Razão Social"
              error={errors.social_reason}
              variant="outlined"
              value={values.social_reason}
              onChange={(e) =>
                setValues({ ...values, social_reason: e.target.value })
              }
            />
            <TextField
              label="CNPJ"
              error={errors.cnpj}
              variant="outlined"
              value={values.cnpj}
              onChange={(e) =>
                setValues({ ...values, cnpj: normalizeCnpj(e.target.value) })
              }
            />
            <TextField
              label="E-mail"
              error={errors.company_email}
              variant="outlined"
              value={values.company_email}
              onChange={(e) =>
                setValues({ ...values, company_email: e.target.value })
              }
            />
            <div className="d-flex">
            <TextField
              style={{ minWidth: '120px' }}
              type="color"
              label="Cor Primária"
              error={errors.color_primary}
              variant="outlined"
              value={values.color_primary}
              onChange={(e) =>
                setValues({ ...values, color_primary: e.target.value })
              }
            />
            <TextField
              style={{ minWidth: '120px' }}
              className="ml-4"
              type="color"
              label="Cor Secundária"
              error={errors.color_secondary}
              variant="outlined"
              value={values.color_secondary}
              onChange={(e) =>
                setValues({ ...values, color_secondary: e.target.value })
              }
            />
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-4">
          <legend>Usuário</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Nome"
              error={errors.user_name}
              variant="outlined"
              value={values.user_name}
              onChange={(e) =>
                setValues({ ...values, user_name: e.target.value })
              }
            />
            <TextField
              label="E-mail"
              error={errors.user_email}
              variant="outlined"
              value={values.user_email}
              onChange={(e) =>
                setValues({ ...values, user_email: e.target.value })
              }
            />
          </div>
        </fieldset>
        <Button color="primary" variant="contained" className="mt-4" disabled={loading} onClick={handleSubmit}>
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default CompanyAdd;

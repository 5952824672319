import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

function Login(props) {
  useEffect(() => {
    localStorage.removeItem('persist:build-demo4');
    localStorage.removeItem('persist:i18n');
    localStorage.removeItem('persist:Tupi - Checkout-auth');
    localStorage.removeItem('Tupi - Checkout-lastLocation_expiresIn');
    localStorage.removeItem('Tupi - Checkout-lastLocation');
  }, [])
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    padding: "6px 15px"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ padding: "6px 15px" });
  };

  return (
    <>
      <div className="kt-login__body login_wrap_logo">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="text-center" style={{ marginRight: '40px' }}>
        <img src={toAbsoluteUrl("/media/logos/logo-4-blue.png")} alt="Logo" style={{ width: '100%', maxWidth: '150px' }}/>
        <h3 className="mt-4">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        </div>
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            className="ml-4"
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              login(values.email, values.password)
                .then(({ data: { token } }) => { // Passa token de acesso
                  disableLoading();
                  props.login(token);
                })
                .catch(() => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="d-flex flex-column justify-content-center align-items-center"
                  onSubmit={handleSubmit}
                >
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      className="mt-0 mb-0"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                    <TextField
                      type="password"
                      margin="normal"
                      label="Senha"
                      className="mt-0 mb-0"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  <div className="mt-4">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Entrar
                  </button>
                  <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot ml-4"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link> 
                  </div>
                </form>
              )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);

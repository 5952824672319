import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import axios from "axios";
import Header from "./header/Header";
// import SubHeader from "./sub-header/SubHeader";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig
}) {
  const [MenuConfig, setMenuConfig] = useState({});
  const [, setColorCompany] = useState({
    primary: '',
    secondary: '',
  });
  useEffect(() => {
    async function generateMenu() {
      var items = [];
    
      const menuItems = [
        {
          id: "dashboard",
          item: {
            title: "Painel",
            root: true,
            alignment: "left",
            page: "dashboard",
          },
        },
        {
          id: "products",
          item: {
            title: "Produtos",
            root: true,
            alignment: "left",
            page: "products",
          },
        },
        {
          id: "orders",
          item: {
            title: "Pedidos",
            root: true,
            alignment: "left",
            page: "orders"
          },
        },
        {
          id: "payment_links",
          item: {
            title: "Links de Pagamento",
            root: true,
            alignment: "left",
            page: "payment_links"
          },
        },
        {
          id: "acl",
          item: {
            title: "ACL",
            root: true,
            alignment: "left",
            submenu: ["permissions", "roles"]
          },
        },
        {
          id: "company",
          item: {
            title: "Empresas",
            root: true,
            alignment: "left",
            submenu: ["company-create"]
          },
        },
        {
          id: "users",
          item: {
            title: "Usuários",
            root: true,
            alignment: "left",
            page: "users",
          },
        },
        {
          id: "settings",
          item: {
            title: "Configurações",
            root: true,
            alignment: "left",
            submenu: ["filiais", "scripts", "catalog_xml", "company_edit", "sellers"]
          }
        }
      ];
    
      const subMenuItems = [
        {
          id: "company-create",
          item: {
            title: "Criar Empresa",
            root: true,
            alignment: "left",
            page: "company/add",
          },
        },
        {
          id: "permissions",
          item: {
            title: "Permissões",
            root: true,
            alignment: "left",
            page: "acl/permissions",
          },
        },
        {
          id: "roles",
          item: {
            title: "Perfis",
            root: true,
            alignment: "left",
            page: "acl/roles",
          },
        },
        {
          id: "filiais",
          item: {
            title: "Filiais",
            root: true,
            alignment: "left",
            page: "filiais",
          },
        },
        {
          id: "scripts",
          item: {
            title: "Códigos de Incorporação",
            root: true,
            alignment: "left",
            page: "scripts",
          },
        },
        {
          id: "catalog_xml",
          item: {
            title: "Catálogo XML",
            root: true,
            alignment: "left",
            page: "catalog_xml"
          }
        },
        {
          id: "company_edit",
          item: {
            title: "Editar dados da Empresa",
            root: true,
            alignment: "left",
            page: "company/edit",
          }
        },
        {
          id: "sellers",
          item: {
            title: "Vendedores",
            root: true,
            alignment: "left",
            page: "sellers",
          }
        }
      ];
    
      // var menuPermissions = [{ item: "dashboard" },{ item: "delivery_histories" }, { item: "cost" }, { item: "avaliations" }, { item: "register", submenu: ["restaurant", "delivery"] }];

      const { data } = await axios.get('/me');

      var menuPermissions = [{ item: "dashboard" }];

      let submenu_company = [];

      data.permissions.forEach(i => {
        if(i === 'create-company') {
          submenu_company.push("company-create")
        }
      });
      if(submenu_company.length > 0) {
        menuPermissions.push({
          item: "company",
          submenu: submenu_company,
        })
      }
      data.roles.find(i => i.slug === 'master' && menuPermissions.push({
        item: "acl",
        submenu: ["permissions", "roles"]
      }));

      if(!data.roles.find(i => i.slug === 'master')) {
        if(data.permissions.find(i => i === 'view-orders')) {
          menuPermissions.push({
            item: "orders"
          })
        }
        if(data.permissions.find(i => i === 'create-product') && data.permissions.find(i => i === 'update-product')) {
          menuPermissions.push({
            item: "products"
          });
        }
        if(data.permissions.find(i => i === 'create-payment-link') || data.permissions.find(i => i === 'view-payments-links')) {
          menuPermissions.push({
            item: "payment_links"
          });
        }
      }
      data.roles.find(i => i.slug === 'owner' && menuPermissions.push({
        item: "users"
      }) && menuPermissions.push({
        item: "settings",
        submenu: ["filiais", "scripts", "catalog_xml", "company_edit", "sellers"]
      }));

      let i = 0;
    
      while (i < menuPermissions.length) {
        const { item: filterMenu } = menuItems.find(
          (item) => item.id === menuPermissions[i].item // eslint-disable-line
        );
        if (menuPermissions[i].submenu !== undefined) {
          var c = 0;
          var subItens = [];
          while (c < menuPermissions[i].submenu.length) {
            var { item: filterSubMenu } = subMenuItems.find(
              (item) => item.id === menuPermissions[i].submenu[c] // eslint-disable-line
            );
            subItens.push(filterSubMenu);
            c++;
          }
          items.push({ ...filterMenu, submenu: subItens });
        } else {
          items.push(filterMenu);
        }
        i++;
      }
    
      const { data: user } = await axios.get('/me')
      setColorCompany({
        primary: user.company ? user.company.color_primary : '#444',
        secondary: user.company ? user.company.color_secondary : '#444',
      });

      return setMenuConfig({
        header: {
          self: {},
          items,
        }
      });
    }
    generateMenu();
  }, [])
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const contentContainerCssClasses = htmlClassService.classes.content_container.join(
    " "
  );
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/* <!-- end:: Header --> */}
            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              {/* <!-- begin:: Aside Left --> */}
              {asideDisplay && (
                <>
                  <div
                    className={`kt-container ${contentContainerCssClasses} kt-container--fit kt-grid kt-grid--ver`}
                  >
                    <AsideLeft />
                    <div
                      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                      id="kt_content"
                    >
                      <KtContent>{children}</KtContent>
                    </div>
                  </div>
                </>
              )}
              {!asideDisplay && (
                <>
                  {/* <!-- begin:: Content --> */}
                  <div
                    id="kt_content"
                    className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {/* {subheaderDisplay && <SubHeader />} */}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    <KtContent>{children}</KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
                  {/* <!-- end:: Content --> */}
                </>
              )}

              {/* <!-- end:: Aside Left --> */}
            </div>
            <Footer />
          </div>
        </div>

        {/* <!-- end:: Body --> */}
      </div>
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display")
});

export default withRouter(connect(mapStateToProps)(Layout));

import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyAdd from "./CompanyAdd";
import CompanyEdit from "./CompanyEdit";

export default function CompanyPage() {
  return (
    <Switch>
      <Route exact path="/company/add" component={CompanyAdd} />
      <Route exact path="/company/edit" component={CompanyEdit} />
    </Switch>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import clsx from "clsx";
import { injectIntl } from "react-intl";
import * as auth from "../../../store/ducks/auth.duck";
import { FormControl, TextField } from '@material-ui/core';

import { toast } from 'react-toastify';

function ProfileEdit(props) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    if(values.name === "" || values.cnpj === "" || values.email === "") {
      setError(true);
    }else{
      setError(false);
    }
  }, [values.email, values.name, values.cnpj]);

  useEffect(() => {
    async function loadUser() {
      setLoading(true)
      const response = await axios.get(`/me`);
      setValues(response.data);
      setLoading(false)
    }
    loadUser();
  }, []);

  async function handleSubmit() {
    setLoading(true);
    try {
      const response = await axios.put('/user', {
        email: values.email,
        name: values.name
      });
      props.updateUser(response.data);
      toast.info('Usuário atualizado')
    }catch(err) {
      if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro nos nossos servidores, contate o suporte técnico.')
      }else{
        toast.error('Ocorreu um erro, tente novamente.')
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Meu Perfil
            </h3>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', backgroundColor: '#fff', padding: '40px', borderRadius: '6px'}} className="d-flex flex-column">
        <div className="d-flex justify-content-start align-items-center">
          <i className="fas fa-user" style={{ background: '#eee', padding: '15px', fontSize: '40px', borderRadius: '6px' }}></i>
          <div className="ml-4">
            <h5>{values.name || '-'}</h5>
            <p>{values.roles && values.roles.map(r => r.name).join(',')}</p>
          </div>
        </div>
      <FormControl className="mt-4">
        <TextField 
          margin="normal"
          variant="outlined"
          label="Nome"
          type="text"
          value={values.name || ''}
          onChange={e => setValues({...values, name: e.target.value})}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Email"
          type="email"
          value={values.email || ''}
          onChange={e => setValues({...values, email: e.target.value})}
          required
        />
        <button
          type="button"
          disabled={error || loading}
          onClick={handleSubmit}
          className={`btn btn-primary btn-elevate kt-login__btn-primary mt-4 ${clsx({"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading})}`} // eslint-disable-line
          >Atualizar</button>
      </FormControl>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ProfileEdit)
);

import React from "react";
import { Route, Switch } from "react-router-dom";
import ScriptsIndex from "./ScriptsIndex";

export default function ProductPage() {
  return (
    <Switch>
      <Route exact path="/scripts" component={ScriptsIndex} />
    </Switch>
  );
}

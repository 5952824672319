import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import { TextField, Button, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

import { toast } from "react-toastify";
import { emailIsValid, normalizeCnpj } from '../utils/normalize';

function UserAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    confirmPassword: "",
    e_rede_credencial_id: "",
  });
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [credencialsOptions, setCredencialOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('/me');
      setUser(data);
      setValues({
        ...values,
        e_rede_credencial_id: data.e_rede_credencial_id
      });
    }
    loadUser();
  }, []) // eslint-disable-line

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get("/filials");
      setCredencialOptions(data);
    }
    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get('/role/users');
      setRolesOptions(data);
    }
    loadData();
  }, [])

  async function handleSubmit() {
    setErrors({
      name: "",
      email: "",
      role: "",
      password: "",
      confirmPassword: "",
    });
    let lock_error = {
      name: "",
      email: "",
      role: "",
      password: "",
      confirmPassword: "",
    };
    let count = 0;

    if(values.name.length < 3) {
      lock_error.name = 'PREENCHA ESSE CAMPO';
      count += 1;
    }
    if(values.email.length < 3) {
      lock_error.email = 'PREENCHA ESSE CAMPO';
      count += 1;
    }else if(!emailIsValid(values.email)) {
      lock_error.email = 'E-MAIL NÃO É VÁLIDO';
      count += 1;
    }
    if(values.role === '') {
      lock_error.role = 'PREENCHA ESSE CAMPO';
      count += 1;
    }
    if(values.password.length < 6) {
      lock_error.password = 'MÍNIMO 6 CARACTERES';
      count += 1;
    }else if(values.password !== values.confirmPassword) {
      lock_error.password = 'SENHAS NÃO COINCIDEM';
      lock_error.confirmPassword = 'SENHAS NÃO COINCIDEM';
      count += 1;
    }

    if(count > 0) {
      return setErrors(lock_error);
    }

    setLoading(true);
    try {
      await axios.post("/user", { ...values, roles: [values.role] });
      setValues({
        name: "",
        email: "",
        role: "",
        password: "",
        confirmPassword: "",
        e_rede_credencial_id: ""
      });
      toast.info("Usuário cadastrado");
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastrar Usuário</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/users/add`}
              >
                Cadastrar Usuário
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Novo Usuário</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridGap: "15px",
            }}
          >
            <FormControl variant="outlined" error={errors.role !== ''}>
              <InputLabel id="role-label">
                Perfil
              </InputLabel>
              <Select error={errors.role !== ''} labelId="role-label" value={values.role} onChange={(e) => setValues({ ...values, role: e.target.value })}>
                {rolesOptions.length > 0 && rolesOptions.map(i => (
                  <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Nome"
              variant="outlined"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              error={errors.name !== ''}
              helperText={errors.name}
            />
            <TextField
              label="E-mail"
              variant="outlined"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              error={errors.email !== ''}
              helperText={errors.email}
            />
            <TextField
              label="Senha"
              type="password"
              variant="outlined"
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              error={errors.password !== ''}
              helperText={errors.password}
            />
            <TextField
              label="Confirmar Senha"
              type="password"
              variant="outlined"
              value={values.confirmPassword}
              onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
              error={errors.confirmPassword !== ''}
              helperText={errors.confirmPassword}
            />
            <FormControl size="small" variant="outlined">
                <InputLabel id="filial-label">Filial</InputLabel>
                <Select
                  size="small"
                  labelId="filial-label"
                  value={values.e_rede_credencial_id}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      e_rede_credencial_id: e.target.value,
                    })
                  }
                  disabled={user.e_rede_credencial_id}
                >
                  {credencialsOptions.length > 0 &&
                    credencialsOptions.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.filial} | {normalizeCnpj(i.cnpj)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
          </div>
        </fieldset>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={loading}
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default UserAdd;

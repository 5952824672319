import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Paper, makeStyles, LinearProgress } from "@material-ui/core";

import { toast } from "react-toastify";

import { normalizeCnpj } from '../utils/normalize';

export default function CatalogXmlIndex() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const [filiais, setFiliais] = useState([]);

  async function loadPermissions() {
    setLoading(true);
    try {
      const { data } = await axios.get("/filials");
      setFiliais(data);
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadPermissions();
  }, []); // eslint-disable-line

  useEffect(() => {
    async function loadUser() {
      setLoading(true);
      const { data } = await axios.get("/me");
      setUser(data);
      setLoading(false);
    }
    loadUser();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
      minHeight: "350px",
    },
  }));
  const classes = useStyles();

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Catálogo XML</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <h3 className="text-center mb-4">
          Abaixo os catálogos XML disponíveis para utilização
        </h3>
        <hr />
        <div className="row">
          {filiais.map(filial => (
            <div className="col-md-6" key={filial.id}>
              <h5 className="text-center">{filial.filial}<br />{normalizeCnpj(filial.cnpj)}</h5>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: '15px', borderRadius: '6px', boxShadow: '0px 0px 5px rgba(0,0,0,.1)' }}>
                  <i className="fab fa-google text-success mb-2" style={{ fontSize: '50px' }}></i>
                  <small>
                    Google Merchant
                  </small>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <button className="btn d-block btn-sm btn-secondary ml-2" type="button" onClick={() => copyToClipboard(`${process.env.REACT_APP_API_BASE_URL}/catalog/merchant/${user.company_id}?download=true&filial=${filial.id}`)}>
                    <i className="fas fa-link mr-2"></i>Copiar Link
                    </button>
                    <a className="btn d-block btn-sm btn-secondary" href={`${process.env.REACT_APP_API_BASE_URL}/catalog/merchant/${user.company_id}?download=true&filial=${filial.id}`} target="blank" download>
                      <i class="fas fa-download mr-2"></i>Baixar XML
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center ml-4" style={{ padding: '15px', borderRadius: '6px', boxShadow: '0px 0px 5px rgba(0,0,0,.1)' }}>
                  <i className="fab fa-facebook text-primary mb-2" style={{ fontSize: '50px' }}></i>
                  <small>
                    Facebook Events
                  </small>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <button className="btn d-block btn-sm btn-secondary ml-2" type="button" onClick={() => copyToClipboard(`${process.env.REACT_APP_API_BASE_URL}/catalog/facebook/${user.company_id}?download=true&filial=${filial.id}`)}>
                      <i className="fas fa-link mr-2"></i>Copiar Link
                    </button>
                    <a className="btn d-block btn-sm btn-secondary" href={`${process.env.REACT_APP_API_BASE_URL}/catalog/facebook/${user.company_id}?download=true&filial=${filial.id}`} target="blank" download>
                      <i class="fas fa-download mr-2"></i>Baixar XML
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: '24px', paddingTop: '24px', borderTop: '1px solid #c9c9c9' }}>
              <h5 className="text-center">Todas as Filiais</h5>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: '15px', borderRadius: '6px', boxShadow: '0px 0px 5px rgba(0,0,0,.1)' }}>
                  <i className="fab fa-google text-success mb-2" style={{ fontSize: '50px' }}></i>
                  <small>
                    Google Merchant
                  </small>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <button className="btn d-block btn-sm btn-secondary ml-2" type="button" onClick={() => copyToClipboard(`${process.env.REACT_APP_API_BASE_URL}/catalog/merchant/${user.company_id}?download=true`)}>
                    <i className="fas fa-link mr-2"></i>Copiar Link
                    </button>
                    <a className="btn d-block btn-sm btn-secondary" href={`${process.env.REACT_APP_API_BASE_URL}/catalog/merchant/${user.company_id}?download=true`} target="blank" download>
                      <i class="fas fa-download mr-2"></i>Baixar XML
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center ml-4" style={{ padding: '15px', borderRadius: '6px', boxShadow: '0px 0px 5px rgba(0,0,0,.1)' }}>
                  <i className="fab fa-facebook text-primary mb-2" style={{ fontSize: '50px' }}></i>
                  <small>
                    Facebook Events
                  </small>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <button className="btn d-block btn-sm btn-secondary ml-2" type="button" onClick={() => copyToClipboard(`${process.env.REACT_APP_API_BASE_URL}/catalog/facebook/${user.company_id}?download=true`)}>
                      <i className="fas fa-link mr-2"></i>Copiar Link
                    </button>
                    <a className="btn d-block btn-sm btn-secondary" href={`${process.env.REACT_APP_API_BASE_URL}/catalog/facebook/${user.company_id}?download=true`} target="blank" download>
                      <i class="fas fa-download mr-2"></i>Baixar XML
                    </a>
                  </div>
                </div>
              </div>
            </div>
      </Paper>
    </div>
  );
}

export default function generateMenu() {
  var items = [];

  const menuItems = [
    {
      id: "video",
      item: {
        title: "Vídeos",
        root: true,
        alignment: "left",
        page: "videos",
      },
    },
    {
      id: "company",
      item: {
        title: "Empresas",
        root: true,
        alignment: "left",
        page: "companies",
      },
    },
    {
      id: "users",
      item: {
        title: "Usuários",
        root: true,
        alignment: "left",
        page: "users",
      },
    },
    {
      id: "configuration",
      item: {
        title: "Configurações",
        root: true,
        alignment: "left",
      },
    },
  ];

  const subMenuItems = [
    {
      id: "perfil",
      item: {
        title: "Perfil",
        root: true,
        alignment: "left",
        page: "roles",
      },
    },
    {
      id: "permission",
      item: {
        title: "Permissão",
        root: true,
        alignment: "left",
        page: "permissions",
      },
    },
  ];

  var menuPermissions = [
    { item: "video" },
    // { item: "company" },
    { item: "users" },
    // { item: "configuration", submenu: ["perfil", "permission"] },
  ];

  let i = 0;

  while (i < menuPermissions.length) {
    const { item: filterMenu } = menuItems.find(
      (item) => item.id === menuPermissions[i].item // eslint-disable-line
    );
    if (menuPermissions[i].submenu !== undefined) {
      var c = 0;
      var subItens = [];
      while (c < menuPermissions[i].submenu.length) {
        var { item: filterSubMenu } = subMenuItems.find(
          (item) => item.id === menuPermissions[i].submenu[c] // eslint-disable-line
        );
        subItens.push(filterSubMenu);
        c++;
      }
      items.push({ ...filterMenu, submenu: subItens });
    } else {
      items.push(filterMenu);
    }
    i++;
  }

  return items;
}

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserUpdate: "[Update User] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
    { storage, key: "Tupi - Checkout-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken } = action.payload;
          return { authToken, user: undefined };
        }

        case actionTypes.Register: {
          const { authToken } = action.payload;

          return { authToken, user: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          const { user } = action.payload;

          return { ...state, user };
        }

        case actionTypes.UserUpdate: {
          const { user } = action.payload;

          return { ...state, user }
        }

        default:
          return state;
      }
    }
);

export const actions = {
  // Depois da autenticação na página de login ser feita, é retornado o token
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  // Depois que faz o loginSaga é disparado o userRequest
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  updateUser: user => ({ type: actionTypes.UserUpdate, payload: { user }}),
};

export function* saga() {
  // Quando é disparado o login, o saga busca os dados do usuário através do token
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(actions.requestUser(payload.authToken));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({ payload }) {
    const { data: user } = yield getUserByToken(payload.user); // Creio que falta enviar o token por aqui

    yield put(actions.fulfillUser(user));
  });
}

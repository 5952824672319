import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { Modal, Button } from 'react-bootstrap';
import { Warning } from "@material-ui/icons";

function ModalConfirm({ open, setOpen, onConfirm, loading }) {
  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Body>
        <h3 className="text-center text-danger mt-4 mb-4">
          {loading ? <CircularProgress /> : <Warning style={{ fontSize: '46px' }} />}<br />
          Você tem certeza que deseja excluir este código de incorporação?
        </h3>
        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
          <Button type="button" size="sm" variant="outline-danger" disabled={loading} onClick={onConfirm}>
            Sim, excluir
          </Button>
          <Button type="button" size="sm" className="ml-2" disabled={loading} variant="danger" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfirm;
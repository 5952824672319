import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { CameraAlt } from "@material-ui/icons";
import { normalizeCnpj, toCurrency } from "../utils/normalize";

import { toast } from "react-toastify";

function ProductAdd() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    value: "",
    e_rede_credencial_id: "",
    active: 1,
  });
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);
  const [credencialsOptions, setCredencialOptions] = useState([]);

  const preview = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('/me');
      setUser(data);
      setValues({
        ...values,
        e_rede_credencial_id: data.e_rede_credencial_id
      });
    }
    loadUser();
  }, []) // eslint-disable-line

  useEffect(() => {
    async function loadData() {
      const { data } = await axios.get("/filials");
      setCredencialOptions(data);
    }
    loadData();
  }, []);

  async function handleSubmit() {
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append("image", image);
      obj.append("name", values.name);
      obj.append("description", values.description);
      obj.append(
        "value",
        Number(values.value.replace(/[^\d]+/g, "").replace(" ", ""))
      );
      obj.append("e_rede_credencial_id", values.e_rede_credencial_id);
      obj.append("active", values.active);
      await axios.post("/products", obj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setValues({
        name: "",
        description: "",
        value: "",
        e_rede_credencial_id: "",
      });
      setImage(null);
      toast.info("Produto cadastrado");
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastrar Produto</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/products`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/products/add`}
              >
                Cadastrar Produto
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Novo Produto</legend>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <label
                htmlFor="image_input"
                className="mr-3"
                style={{
                  width: "120px",
                  height: "120px",
                  border: "1px solid #c9c9c9",
                  borderRadius: "4px",
                  backgroundImage: `url(${preview})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CameraAlt />
                <input
                  type="file"
                  id="image_input"
                  style={{ display: "none" }}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </label>
              <small>
                Máximo de 2MB
              </small>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "5px",
              }}
            >
              <TextField
                size="small"
                label="Nome do Produto"
                variant="outlined"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
              <TextField
                size="small"
                label="Descrição"
                variant="outlined"
                value={values.description}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
              />
              <TextField
                size="small"
                label="Valor"
                multiline
                variant="outlined"
                value={values.value}
                onChange={(e) =>
                  setValues({ ...values, value: toCurrency(e.target.value) })
                }
              />
              <FormControl size="small" variant="outlined">
                <InputLabel id="filial-label">Filial</InputLabel>
                <Select
                  size="small"
                  labelId="filial-label"
                  value={values.e_rede_credencial_id}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      e_rede_credencial_id: e.target.value,
                    })
                  }
                  disabled={user.e_rede_credencial_id}
                >
                  {credencialsOptions.length > 0 &&
                    credencialsOptions.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.filial} | {normalizeCnpj(i.cnpj)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="medium"
                    checked={values.active === 1}
                    onChange={() =>
                      setValues({
                        ...values,
                        active: values.active === 1 ? 0 : 1,
                      })
                    }
                  />
                }
                label="Ativo"
              />
            </div>
          </div>
        </fieldset>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={
            loading ||
            values.name === "" ||
            values.description === "" ||
            values.value === "" ||
            values.e_rede_credencial_id === "" ||
            values.value === "R$ 0,00" ||
            image === null
          }
          onClick={handleSubmit}
        >
          Cadastrar
        </Button>
      </form>
    </>
  );
}

export default ProductAdd;

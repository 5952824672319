import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormControl, TextField } from '@material-ui/core';
import clsx from "clsx";
import { toast } from "react-toastify";

export default function ProfilePassword({ history, match }) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [disabled, setDisabled] = useState(true);
  
  useEffect(() => {
    if(values.password !== "" && values.password === values.confirmPassword) {
      setDisabled(false);
    }else{
      setDisabled(true);
    }
  }, [values]);

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.put(`/user/password`, { password: values.password, });
      history.push('/dashboard');
      toast.info("Senha atualizada")
    }catch(err) {
      toast.error('Ocorreu um erro ao atualizar senha')
    }
    setLoading(false);
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Meu Perfil
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/profile/`}>
                Meu Perfil
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/profile/password`}>
                Editar Senha
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FormControl style={{ width: '100%', backgroundColor: '#fff', padding: '20px', borderRadius: '6px'}}>
        <TextField 
          margin="normal"
          variant="outlined"
          label="Nova Senha"
          type="password"
          defaultValue={values.password}
          onChange={(e) => setValues({ ...values, password: e.target.value })}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Repetir Senha"
          type="password"
          defaultValue={values.confirmPassword}
          onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
          required
        />
        <button
          type="button"
          disabled={disabled}
          onClick={handleSubmit}
          className={`btn btn-primary btn-elevate kt-login__btn-primary mt-4 ${clsx({"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading})}`} // eslint-disable-line
          >Atualizar</button>
      </FormControl>
    </>
  );
}


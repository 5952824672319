import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link, useHistory } from "react-router-dom";

import { TextField, Button } from "@material-ui/core";

import { toast } from "react-toastify";
import { normalizeCnpj } from "../utils/normalize";

function FilialEdit({match}) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    filial: "",
    cnpj: "",
    pv: "",
    token: "",
  });

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await axios.get(`/e-rede-credencials/${id}`);
        setValues({
          filial: data.filial,
          cnpj: normalizeCnpj(data.cnpj),
          pv: data.pv,
          token: data.token,
        });
      }catch(err) {
        if(err.response && err.response.status === 403) {
          toast.error('Você não tem permissão para este recurso');
        }else if(err.response && err.response.status === 400) {
          toast.error(err.response.data.msg);
        }else if(err.response && err.response.status === 500) {
          toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
        }else{
          toast.error('Ocorreu um erro, tente novamente');
        }
      }
    }
    if(id) {
      loadData();
    }
  }, [id]);

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.put(`/e-rede-credencials/${id}`, {
        ...values,
        cnpj: values.cnpj.replace(/[^\d]/g, '')
      });
      setValues({
        filial: "",
        cnpj: "",
        pv: "",
        token: "",
      });
      toast.info("Filial atualizada");
      history.push('/filiais')
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Criar Filial</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/filiais`}>
                Lista
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/filiais/add`}
              >
                Criar Filial
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "6px",
        }}
        className="shadow"
      >
        <fieldset>
          <legend>Nova Filial</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="Razão Social"
              variant="outlined"
              value={values.filial}
              onChange={(e) => setValues({ ...values, filial: e.target.value })}
            />
            <TextField
              label="CNPJ"
              variant="outlined"
              value={values.cnpj}
              onChange={(e) => setValues({ ...values, cnpj: normalizeCnpj(e.target.value) })}
            />
          </div>
        </fieldset>
        <fieldset className="mt-4">
          <legend>Credenciais e-Rede</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "15px",
            }}
          >
            <TextField
              label="PV"
              multiline
              variant="outlined"
              value={values.pv}
              onChange={(e) => setValues({ ...values, pv: e.target.value })}
            />
            <TextField
              label="Token"
              multiline
              variant="outlined"
              value={values.token}
              onChange={(e) => setValues({ ...values, token: e.target.value })}
            />
          </div>
        </fieldset>
        <Button
          color="primary"
          variant="contained"
          className="mt-4"
          disabled={
            loading ||
            values.filial === "" ||
            values.cnpj.length < 18 ||
            values.pv === "" ||
            values.token === ""
          }
          onClick={handleSubmit}
        >
          Editar
        </Button>
      </form>
    </>
  );
}

export default FilialEdit;
